import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from "qs";


const getItems = (args
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            page: args.page,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            common: args.common,
        }


        apiClient.get(endpoints.brandDnmContact, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const createItems = (
    emails
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            emails: emails,
        }

        apiClient.post(endpoints.brandDnmContact, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const markToDelete = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.brandDnmContact + "/" + itemId)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}


const markToRetry = (
    {itemId}
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.post(endpoints.brandDnmContact + "/mark-to-retry/" + itemId)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}


const markMultipleToDelete = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items_to_delete: Object.keys(items),
        }

        apiClient.post(endpoints.brandDnmContact + "/delete-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const markMultipleToRetry = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items: Object.keys(items),
        }

        apiClient.post(endpoints.brandDnmContact + "/mark-to-retry-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}


export const brandDnmContactService = {
    getItems,
    createItems,
    markToDelete,
    markToRetry,
    markMultipleToDelete,
    markMultipleToRetry
}
