import React from 'react';
import {Grid} from "@mui/material";
import ControlledTextField from "../../ControlledTextField";

const EmailCsv = ({control}) => {
    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={12}>
                <ControlledTextField
                    control={control}
                    name={"emails_csv"}
                    label={"Emails"}
                    multiline
                    rows={20}
                    placeholder="e.g., email1@example.com, email2@example.com"
                />
            </Grid>
        </Grid>
    );
};

export default EmailCsv;