import React from 'react';
import {Box, Typography} from "@mui/material";
import CustomIconButton from "../../CustomIconButton";
import {Delete, Warning} from "@mui/icons-material";

function WarningIcon(props) {
    return null;
}


const EmailListItem = ({item, onItemDelete}) => {
    return (
        <Box
            sx={{
                p: "8px",
                backgroundColor: "primary.light",
                borderRadius: "8px",
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                my:2
            }}
            width='100% !important'
        >
            <Typography variant="body2" sx={{flexGrow: 1}}>
                {item.email}
            </Typography>
            {!item.isValid && (
                <Warning sx={{color: "error.main", mr: 2}} />
            )}
            <CustomIconButton onClick={() => onItemDelete(item.email)}>
                <Delete />
            </CustomIconButton>
        </Box>
    );
};

export default EmailListItem;