import {routes} from "../utils/routes";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReportIcon from '@mui/icons-material/Assessment';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ScheduleIcon from '@mui/icons-material/Schedule';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import CategoryIcon from '@mui/icons-material/Category';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import StorageIcon from '@mui/icons-material/Storage';  // For ESP Integrations
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import LaunchIcon from '@mui/icons-material/Launch';
import LabelIcon from '@mui/icons-material/Label';
import DescriptionIcon from '@mui/icons-material/Description';
import ListIcon from '@mui/icons-material/List';
import GroupIcon from '@mui/icons-material/Group';
import CampaignIcon from '@mui/icons-material/Campaign';
import ArticleIcon from '@mui/icons-material/Article';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import BarChartIcon from '@mui/icons-material/BarChart';
import MailIcon from '@mui/icons-material/Mail';
import {
    ArrowDownward,
    ArrowOutward,
    AutoMode, Build,
    HelpOutline,
    People, Person, Phone,
    Radar,
    ScheduleSend,
    SouthWest
} from "@mui/icons-material";

export const navItems = [
    {
        label: "Dashboard",
        path: routes.homePage,
        icon: <DashboardIcon/>
    },
    {
        label: "Reporting",
        collapsible: [
            {
                label: "Content Reporting",
                path: routes.contentReporting,
                icon: <ArticleIcon/>
            },
            {
                label: "Campaign Reporting",
                path: routes.campaignReporting,
                icon: <MailIcon/>
            },
            {
                label: "Offer Reporting",
                path: routes.offerReporting,
                icon: <LocalOfferIcon/>
            },
            {
                label: "List Reporting",
                path: routes.mailingListReporting,
                icon: <StorageIcon/>
            },
            {
                label: "External Reporting",
                path: routes.externalSourceReporting,
                icon: <ArrowOutward/>
            },
            {
                label: "Internal Reporting",
                path: routes.internalSourceReporting,
                icon: <SouthWest/>
            },
            {
                label: "Unknown Reporting",
                path: routes.unknownSourceReporting,
                icon: <HelpOutline/>
            },
            {
                label: "Partner Reporting",
                path: routes.partnerReporting,
                icon: <People/>
            },
            {
                label: "Network Reporting",
                path: routes.networkIntegrationReporting,
                icon: <Radar/>
            }
        ],
        icon: <ReportIcon/>
    },
    {
        label: "Library",
        collapsible: [
            {
                label: "Network Integrations",
                path: routes.networkIntegration,
                icon: <NetworkWifiIcon/>
            },
            {
                label: "Categories",
                path: routes.categories,
                icon: <CategoryIcon/>
            },
            {
                label: "Offers",
                path: routes.offers,
                icon: <LocalOfferIcon/>
            },
            {
                label: "Contents",
                path: routes.contents,
                icon: <DescriptionIcon/>
            },
            {
                label: "ESP Integrations",
                path: routes.espIntegrations,
                icon: <StorageIcon/>
            },
            {
                label: "Brands",
                path: routes.brands,
                icon: <BrandingWatermarkIcon/>
            },
            {
                label: "Verticals",
                path: routes.verticals,
                icon: <VerticalSplitIcon/>
            },
            {
                label: "Tags",
                path: routes.tags,
                icon: <LabelIcon/>
            },
            {
                label: "Dynamic Tags",
                path: routes.dynamicTag,
                icon: <AutoMode/>
            },
            {
                label: "Templates",
                path: routes.templates,
                icon: <DescriptionIcon/>
            },
            {
                label: "Lists",
                path: routes.mailingLists,
                icon: <ListIcon/>
            },
            {
                label: "Lists Groups",
                path: routes.mailingListsGroups,
                icon: <GroupIcon/>
            },
            {
                label: "Source Types",
                path: routes.externalSourceTypes,
                icon: <ViewStreamIcon/>
            },
            {
                label: "Sources",
                path: routes.externalSources,
                icon: <LaunchIcon/>
            },
        ],
        icon: <LibraryBooksIcon/>
    },
    {
        label: "Scheduling",
        collapsible: [
            {
                label: "Manual Schedule",
                path: routes.manualSchedule,
                icon: <ScheduleIcon/>
            },
            {
                label: "Automated Configs",
                path: routes.automatedSchedulingConfigs,
                icon: <ScheduleSend/>
            },
            {
                label: "Campaigns",
                path: routes.campaigns,
                icon: <CampaignIcon/>
            },
        ],
        icon: <ScheduleIcon/>
    },
    {
        label: "Utility",
        collapsible: [
            {
                label: "Brand DNM Contact",
                path: routes.brandDnmContact,
                icon: <Person/>
            },
        ],
        icon: <Build/>
    },
];
