import React from 'react';
import EnhancedTableLoader from "./EnhancedTableLoader";
import {Box, Paper, Table, TableContainer, useTheme} from "@mui/material";

const CustomTable = ({loading=false, rowHeight=null, rowsPerPage, footer, header, body, sx, totals = false}) => {
    const theme = useTheme()
    if (!rowHeight)
        rowHeight = theme.customTokens.defaultRowHeight

    return (
        <Paper sx={{mb: 2, position: "relative", width: "100%"}}>
            {
                loading && <EnhancedTableLoader totals={totals}/>
            }

            <TableContainer
                sx={{
                    maxHeight: rowHeight * (rowsPerPage[0] + totals) + theme.customTokens.defaultRowHeight+ 8 + totals,

                }}
            >
                <Table
                    stickyHeader
                    sx={{tableLayout: "fixed"}}
                    aria-labelledby="tableTitle"
                >
                    {header}
                    {body}
                </Table>
            </TableContainer>
            {footer}
        </Paper>
    );
};

export default CustomTable;