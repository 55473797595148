import React from 'react';
import {
    Grid,
    Typography,
    useTheme,
} from "@mui/material";
import {Delete} from "@mui/icons-material";
import EmailListItem from "./EmailListItem";

const EmailList = ({emails, removeEmail}) => {

    return (
        <Grid container>
            <Grid item xs={12} sx={{height: 460, overflowY: "scroll"}}>

                {Object.values(emails).map((email, index) => (
                    <EmailListItem key={index} item={email} onItemDelete={removeEmail}/>
                ))}
            </Grid>
        </Grid>

    );
};

export default EmailList;