import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";

import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import {verticalService} from "../../../api/services/verticalService";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import EmailCsv from "./EmailCsv";
import {brandDnmContactService} from "../../../api/services/brandDnmContactService";
import EmailList from "./EmailList";

const EMAILS_SCV_PHASE = 1
const EMAILS_CONFIRMATION_PHASE = 2

const schema = yup.object().shape({
    emails_csv: yup.string().required('Required field').min(1, 'Required'),
});


const AddBrandDnmContactModal = ({setSnack, open, handleClose, handleRefreshItems}) => {
    const [loading, setLoading] = useState(false)
    const [emails, setEmails] = useState({});
    const [phase, setPhase] = useState(EMAILS_SCV_PHASE)

    const {
        control,
        handleSubmit,
        reset,
        setError
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            emails_csv: ""
        }
    })

    const onCSVPhaseSuccess = async (data) => {
        const splitEmails = data.emails_csv.split(",").map(email => email.trim()).filter(email => email !== "");
        const emailDict = splitEmails.reduce((acc, email) => {
            acc[email] = {
                email: email,
                isValid: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
            };
            return acc;
        }, {});

        if (Object.keys(emailDict).length > 0) {
            setPhase(EMAILS_CONFIRMATION_PHASE);
            setEmails(emailDict);
            console.log(emailDict)
            console.log(data, "Success");
        } else {
            setError("emails_csv", {
                type: "manual",
                message: "No values provided"
            });
        }
    }

    const onCSVPhaseError = (error) => {
        // Handle Errors
        console.debug(error)
    }

    const onConfirmation = async () => {
        setLoading(true)

        const response = await brandDnmContactService.createItems(Object.values(emails).filter(email => email.isValid).map(email => email.email))

        if (response) {
            setSnack(response.message, "success")
            setLoading(false)
            reset({
                emails_csv: ""
            })
            handleClose()
            handleRefreshItems()
        } else {
            setSnack("Invalid Parameters", "error")
            setLoading(false)
        }
    }


    const handleSubmitByPhase = (event) => {
        event.preventDefault();
        if (phase === EMAILS_SCV_PHASE) {
            handleSubmit(onCSVPhaseSuccess, onCSVPhaseError)();
        } else {
            onConfirmation();
        }
    }

    const handleSecondaryButton = () => {
        if (phase === EMAILS_SCV_PHASE) {
            handleClose()
        } else {
            setPhase(EMAILS_SCV_PHASE)
        }
    }

    const removeEmail = (email) => {
        const newEmails = {...emails};
        delete newEmails[email];
        setEmails(newEmails)
    }

    return (
        <CustomModal
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            onSubmit={handleSubmitByPhase}
        >
            <CustomModalHeader handleClose={handleClose} title={"Create Contacts"}/>
            <CustomModalBody>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        {phase === EMAILS_SCV_PHASE ? (
                            <Typography variant={"body2"} pb={2}>
                                Add a list of comma separated emails.<br/>The emails will be added to all your ESP
                                integrations in <b>all brand's do not email lists</b>.
                            </Typography>
                        ) : (
                            <Typography variant={"body2"} pb={2}>
                                Check the contacts before creating them, only contacts that do not have any issues will
                                be created (<b>{Object.values(emails).filter(email => email.isValid).length}</b> valid emails).
                            </Typography>
                        )}
                    </Grid>

                </Grid>
                {phase === EMAILS_SCV_PHASE && <EmailCsv control={control}/>}
                {phase === EMAILS_CONFIRMATION_PHASE && <EmailList emails={emails} removeEmail={removeEmail}/>}
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={handleSecondaryButton}
                        fullWidth
                        variant={"outlined"}
                    >
                        {phase === EMAILS_SCV_PHASE ? "Cancel" : "Back"}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={phase === EMAILS_SCV_PHASE ? "Next" : "Create Contacts"}
                        loading={loading}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(AddBrandDnmContactModal);