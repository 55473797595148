import React, {useEffect, useState} from 'react';
import {Box, Tooltip, useTheme} from "@mui/material";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {Delete, Edit, Refresh} from "@mui/icons-material";
import ConfirmationModal from "../components/ConfirmationModal";
import {brandDnmContactService} from "../api/services/brandDnmContactService";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import useSelectableData from "../hooks/useSelectableData";
import useDeleteData from "../hooks/useDeleteData";
import useDeleteMultipleData from "../hooks/useDeleteMultipleData";
import useUpdateData from "../hooks/useUpdateData";
import useAddData from "../hooks/useAddData";
import AddBrandDnmContactModal from "../components/BrandDnmContact/BrandDnmContactModal/AddBrandDnmContactModal";
import Status from "../components/Status";

const BrandDnmContact = () => {
    const theme = useTheme()
    const urlParams = useUrlParams()

    const {
        items,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        brandDnmContactService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
            },
        },
        {},
        {},
        urlParams.queryParams
    )

    const {selectedItems, handleSelectItem, setSelectedItems} = useSelectableData()
    const {
        deleteItem,
        handleOpenDeleteItemModal,
        handleCloseDeleteItemModal,
        handleConfirmDeleteItem,
        deleteItemApi,
    } = useDeleteData(
        brandDnmContactService.markToDelete,
        handleRefreshItems,
        handleSelectItem
    )

    const {
        deleteItem: retryItem,
        handleOpenDeleteItemModal: handleOpenRetryItemModal,
        handleCloseDeleteItemModal: handleCloseRetryItemModal,
        handleConfirmDeleteItem: handleConfirmRetryItem,
        deleteItemApi: retryItemApi,
    } = useDeleteData(
        brandDnmContactService.markToRetry,
        handleRefreshItems,
        handleSelectItem
    )

    const {
        deleteItems,
        handleOpenDeleteMultipleItemsModal,
        handleCloseDeleteMultipleItemsModal,
        handleDeleteMultipleItems,
        deleteItemsApi,
    } = useDeleteMultipleData(
        brandDnmContactService.markMultipleToDelete,
        selectedItems,
        handleRefreshItems,
        setSelectedItems
    )

    const {
        deleteItems: retryItems,
        handleOpenDeleteMultipleItemsModal: handleOpenRetryMultipleItemsModal,
        handleCloseDeleteMultipleItemsModal: handleCloseRetryMultipleItemsModal,
        handleDeleteMultipleItems: handleRetryMultipleItems,
        deleteItemsApi: retryItemsApi,
    } = useDeleteMultipleData(
        brandDnmContactService.markMultipleToRetry,
        selectedItems,
        handleRefreshItems,
        setSelectedItems
    )

    const {
        createItemModalOpen,
        handleOpenCreateItemModal,
        handleCloseCreateItemModal,
    } = useAddData()

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
            {},
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
    ])

    const headCells = [
        {
            id: 'email',
            numeric: false,
            disablePadding: true,
            label: 'Email',
        },
        {
            id: "status",
            numeric: false,
            disablePadding: true,
            combined: "status_message",
            width: "70px",
            label: "Status",
            element: (item, combined) => (
                <Tooltip title={combined.status_message} arrow>
                    <Status size={24}
                            color={item.status < 0 ? theme.palette.warning.main : (item.status === 0 ? theme.palette.success.main : (item.status === 1 ? theme.palette.error.main : theme.palette.grey[500]))}/>

                </Tooltip>
            )
        },
        {
            id: 'updated_at',
            align: "right",
            disablePadding: false,
            label: 'Updated At',
            datetime: true,
            width: "130px"
        },
        {
            id: 'created_at',
            align: "right",
            disablePadding: false,
            label: 'Created At',
            datetime: true,
            width: "130px"
        },
        {
            action: handleOpenRetryItemModal,
            icon: <Refresh/>,
        },
        {
            action: handleOpenDeleteItemModal,
            icon: <Delete/>,
        }
    ];

    const table = useTable(items, itemsPagination, selectedItems, handleSelectItem)

    console.debug(selectedItems)
    return <>
        <Box>
            <CustomAppBar
                handleModalOpen={handleOpenCreateItemModal}
                addButtonText={"Create Contact"}
                selectedItems={selectedItems}
                onDeleteMultipleItems={handleOpenDeleteMultipleItemsModal}
                onRetrySelectedItems={handleOpenRetryMultipleItemsModal}
            />
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        {
            createItemModalOpen &&
            <AddBrandDnmContactModal
                handleClose={handleCloseCreateItemModal}
                handleRefreshItems={handleRefreshItems}
                open={true}
            />
        }
        {deleteItem &&
            <ConfirmationModal
                title={"Delete Contact"}
                description={
                    <>
                        Are you sure you want to delete the <strong>{deleteItem.name}</strong> contact?
                    </>
                }
                handleClose={handleCloseDeleteItemModal}
                handleSubmit={handleConfirmDeleteItem}
                loading={deleteItemApi.loading}
                open={true}
            />
        }
        {retryItem &&
            <ConfirmationModal
                title={"Retry Contact"}
                description={
                    <>
                        Are you sure you want to retry the <strong>{retryItem.name}</strong> contact?
                    </>
                }
                handleClose={handleCloseRetryItemModal}
                handleSubmit={handleConfirmRetryItem}
                loading={retryItemApi.loading}
                open={true}
            />
        }
        {
            (deleteItems && !!Object.keys(selectedItems).length) && <ConfirmationModal
                title={"Delete Contact"}
                description={
                    <>
                        Are you sure you want to
                        delete <strong>{Object.keys(selectedItems).length}</strong> contact(s)?
                    </>
                }
                handleClose={handleCloseDeleteMultipleItemsModal}
                handleSubmit={handleDeleteMultipleItems}
                loading={deleteItemsApi.loading}
                open={true}
            />

        }
        {
            (retryItems && !!Object.keys(selectedItems).length) && <ConfirmationModal
                title={"Retry Contact"}
                description={
                    <>
                        Are you sure you want to retry <strong>{Object.keys(selectedItems).length}</strong> contact(s)?
                    </>
                }
                handleClose={handleCloseRetryMultipleItemsModal}
                handleSubmit={handleRetryMultipleItems}
                loading={retryItemsApi.loading}
                open={true}
            />
        }
    </>
}

export default BrandDnmContact