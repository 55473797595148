import React, {useEffect, useRef} from 'react';
import {Badge, Button, Chip, Divider, Grid, IconButton, InputBase, Paper, Popover, Stack} from "@mui/material";
import {Close, Search, Tune} from "@mui/icons-material";
import CustomTextField from "../CustomTextField";
import CustomAutocompleteSelectField from "../CustomAutocompleteSelectField";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import PrimaryFilterTextField from "./PrimaryFilterTextField";
import CustomPopover from "./CustomPopover";
import {filtersDefaultValues, filtersElementsTypes} from "../../utils/constants";

const Filters = ({filters, sx, ...props}) => {
    // console.debug(filters.filters)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const paperRef = useRef(null);
    const formRef = useRef(null);


    const {
        control,
        handleSubmit,
        reset,
        formState,
        watch
    } = useForm({
        defaultValues: filters.values
    })

        useEffect(() => {
        const fetchData = async () => {
            const fetchedData = await Promise.all(
                Object.entries(filters.fieldsConfigs).map(async ([filterName, filterSettings]) => {
                    if (filterSettings.type === filtersElementsTypes.MULTISELECT_API && filterSettings.defaultValue.length) {
                        console.debug(filterSettings.defaultValue, filterName)
                        const response = await filterSettings.apiCall({
                            paginate: false,
                            [filterName]: filterSettings.defaultValue
                        });

                        return {
                            [filterName]: filterSettings.defaultValue.reduce((acc, filterItem) => {
                                const foundItem = response.data.items.find(item => item.id === filterItem.value);
                                if (foundItem) {
                                    acc.push({value: filterItem.value, key: foundItem.name});
                                }
                                return acc;
                            }, [])
                        };
                    }
                })
            );

            const defaultValues = fetchedData.reduce((acc, curr) => ({...acc, ...curr}), {});
            console.debug(defaultValues)
            reset(defaultValues);
        };

        fetchData();
    }, []);

    useEffect(() => {
        reset({...filters.values});
    }, [filters.values]);

    const handleClick = () => {
        setAnchorEl(paperRef.current); // Use the ref as the anchorEl
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleReset = () => {
        reset();
        const emptyFilters = {}
        Object.keys(filters.fieldsConfigs).forEach(filterName => {
            console.debug(filterName)
            if (filters.fieldsConfigs[filterName].type === filtersElementsTypes.TEXT) {
                emptyFilters[filterName] = filtersDefaultValues[filtersElementsTypes.TEXT]
            } else if (filters.fieldsConfigs[filterName].type === filtersElementsTypes.NUMBER) {
                emptyFilters[filterName] = filtersDefaultValues[filtersElementsTypes.NUMBER]
            } else if (filters.fieldsConfigs[filterName].type === filtersElementsTypes.MULTISELECT) {
                emptyFilters[filterName] = filtersDefaultValues[filtersElementsTypes.MULTISELECT]
            } else if (filters.fieldsConfigs[filterName].type === filtersElementsTypes.MULTISELECT_API) {
                emptyFilters[filterName] = filtersDefaultValues[filtersElementsTypes.MULTISELECT_API]
            }else if (filters.fieldsConfigs[filterName].type === filtersElementsTypes.SELECT) {
                emptyFilters[filterName] = filtersDefaultValues[filtersElementsTypes.SELECT]
            }else if (filters.fieldsConfigs[filterName].type === filtersElementsTypes.DATETIME) {
                emptyFilters[filterName] = filtersDefaultValues[filtersElementsTypes.DATETIME]
            }else if (filters.fieldsConfigs[filterName].type === filtersElementsTypes.TIME) {
                emptyFilters[filterName] = filtersDefaultValues[filtersElementsTypes.TIME]
            }else if (filters.fieldsConfigs[filterName].type === filtersElementsTypes.DATE) {
                emptyFilters[filterName] = filtersDefaultValues[filtersElementsTypes.DATE]
            }
        })
        console.debug(emptyFilters)
        filters.handleChangeFilters(emptyFilters)
    }


    const onSuccess = (data) => {
        console.debug(data)
        filters.handleChangeFilters(data)
        handleClose()
    }

    const onSubmit = handleSubmit(onSuccess)

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    console.debug(filters.primaryFilter)
    return (
        // eslint-disable-next-line react/jsx-no-undef
        <form onSubmit={onSubmit} ref={formRef}>
            <Paper
                sx={{my: 2, p: '2px 4px', display: 'flex', alignItems: 'center', width: 640, ...sx}}

                {...props}

                ref={paperRef}
            >
                <IconButton type="button" sx={{p: '10px'}} aria-label="search" disabled>
                    <Search/>
                </IconButton>
                <PrimaryFilterTextField control={control} name={filters.primaryFilter}/>
                <IconButton type="button" sx={{
                    p: '10px',
                    visibility: (!!filters.activeFiltersNo || !!filters.values[filters.primaryFilter] || formState.isDirty) ? "visible" : "hidden"
                }} aria-label="search" onClick={() => handleReset()}>
                    <Close/>
                </IconButton>
                {
                    filters.additionalFilters > 0 && (<>
                        <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>
                        <IconButton sx={{p: '10px'}} aria-label="directions" onClick={handleClick}>
                            <Badge badgeContent={filters.activeFiltersNo} color="error">
                                <Tune/>
                            </Badge>
                        </IconButton>
                    </>)
                }
            </Paper>
            <CustomPopover
                open={open}
                control={control}
                handleClose={handleClose}
                anchorEl={anchorEl}
                id={id}
                filtersData={filters.fieldsConfigs}
                onSubmit={onSubmit}
                primaryFilter={filters.primaryFilter}
            />
        </form>

    );
};

export default Filters;