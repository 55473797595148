import {IconButton, styled} from "@mui/material";

const CustomIconButton = styled(IconButton)(({theme}) => ({
    padding: 0,
    borderRadius: "4px",
    color: theme.palette.primary.main, // Use primary color from theme
    backgroundColor: theme.palette.common.white, // Use white color from theme
    '&:hover': {
        backgroundColor: theme.palette.action.hover, // Use hover color from theme
    },
}));

export default CustomIconButton;